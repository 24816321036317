import React from "react";
import PropTypes from "prop-types";

import { MetaTags, getProperty } from "@zeus/shared";

export const Meta = ({ data }) => {
  const title = getProperty(data, "title");
  const description = getProperty(data, "description");

  return <MetaTags title={title} description={description} />;
};

Meta.defaultProps = {
  data: {},
};

Meta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
